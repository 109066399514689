export const config = {
    name: 'trajector-file-api',
    // In production, the API automatically uses 'secretName' to retrieve
    // secrets from AWS when it starts. While testing, it uses the default
    // values specified in the 'secrets' object.
    secretName: {
        dev: "trajector-file-api-dev",
        qa: "trajector-file-api-qa",
        uat: "trajector-file-api-uat",
        prod: "trajector-file-api-prod",
        production: "trajector-file-api-prod"
    },
    secrets: {
        apiKey: {
            dev: 'SO7Vw0@dqW^iW0eWMJpocbBPtuc##Z9@',
            qa: 't6l2OF7rwbO3IMPpjv9OVBso',//'eI4Q5cSS0rgWIaq2Q1NXRrqkLkfzO6jNk8U5',
            uat: '6EsWzt5Qaws3HRM9naEWJ3GS',
            prod: 't6l2OF7rwbO3IMPpjv9OVBso',
            production: 't6l2OF7rwbO3IMPpjv9OVBso'
        },
        jwtSecret: 'testJWTSecret',
        pwEncryption: 'testPWEncryption',
        db: '',
        // Load default values here if needed for testing, otherwise
        // handled by the secretsManager.
        tz184User: '',
        tz184Pass: '',
        tt000User: 'it@teamandtech.com',
        tt000Pass: 'da#i8W^@2gKGj%Td&oM&u%H&7CCa7GRx6f^y',
    },
    urlForAPI: {
        dev: 'http://localhost:9004/',
        qa: 'https://api.prod.file.loxleyleadership.com/', //'https://api.qa.file.loxleyleadership.com/
        uat: 'https://api.uat.file.loxleyleadership.com/',
        prod: 'https://api.prod.file.loxleyleadership.com/',
        production: 'https://api.prod.file.loxleyleadership.com/'
    }
}
