import React, { useState, useEffect, useRef } from 'react';
import '../css/fileUploader.css';
import { uploadService } from '../services';

const SmrUploader = () => {
  const [clientConsent, setClientConsent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorField, setErrorField] = useState('');
  const hiddenFileInput = useRef(null);

  /**
   * make sure the user is authorized
   * If not, send them back to /
   */
  useEffect(() => {
    let auth = window.sessionStorage.getItem('auth');

	if (!auth) {
		window.location.href = '/';
	  }
  });

  const onClientConsentChange = (event) => {
    setClientConsent(event.target.checked);
  };

  const errorOccured = (errorMsg) => {
    document.forms[0].reset();
    setClientConsent(document.querySelector('#clientConsent').checked);
    setFiles([]);
    setErrorField(errorMsg);
    return false;
  };

  /**
   * functions for file input component
   */

  const handleFileButtonClick = () => {
    // uncheck client consent to avoid form submission
    // Just in case of pressing the enter key to select the file in the file explorer    
    if (clientConsent) {
      if (document.querySelector('#clientConsent')) {
        document.querySelector('#clientConsent').click();
      } else {
        setClientConsent(false);
        return false;
      }
    }

    hiddenFileInput.current.click();
  };

  const handleFiles = event => {
    let uploadedFiles = event.target.files;
    // add id for removal of file when id is needed
    // just in case the error message has been set
    errorField ? setErrorField('') : '';

    setFiles([...files, ...uploadedFiles]);
  };

  const handleRemove = id => {
    setFiles(files.filter((file, i) => i !== id));
  };

  /**
   * handle form submission
   */
  const submitUploadForm = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // set client filing key to avoid redudency
    let clientFilingKey = window.sessionStorage.getItem('clientFilingKey');

    // declare form data
    let formData = new FormData();
    // set the file in the form data object
    for (let theFile of files) {
      formData.append('file', theFile, theFile.name);
    }

    //set info in formData for logging
    formData.set('clientConsent', clientConsent);
    formData.set('employeeName', window.sessionStorage.getItem('employeeName'));
    formData.set('employeeEmail', window.sessionStorage.getItem('employeeEmail'));
    formData.set('clientName', window.sessionStorage.getItem('clientName'));
    formData.set('clientEmail', window.sessionStorage.getItem('clientEmail'));
    formData.set('clientFilingKey', clientFilingKey);

    try {
      await uploadService(formData, clientFilingKey).then((res) => {
        if (res.error) {
          return errorOccured(res.error);
        }

        // success
        if (res.verified) {
          window.location.href = '/uploadconfirmation';
        }
      }).catch((error) => {
        console.log(error);
        return errorOccured(error);
      });
    } catch (error) {
      return errorOccured(error);
    }
  };

  return (
    <div className="smruploader-container">
      <h2 className="text-center"> Trajector Medical Records Uploader</h2>
      <h3 className="mt-4 sub-header">Choose Files</h3>
      <div>
        <form encType="multipart/form-data" onSubmit={submitUploadForm}>
          {/* collect the file or files to upload */}
          <div className="d-flex flex-row upload-container">
            <div className="form-group upload-files">
              <button id="file-upload-button" className="file-upload-button mt-2" onClick={handleFileButtonClick}>
                Choose Files
              </button>
              <input
                type="file"
                className="form-control"
                style={{ display: 'none' }}
                name="file"
                accept=".pdf, .png, .jpg, .docx, .doc, .mp4, .mov"
                id="file"
                onChange={handleFiles}
                ref={hiddenFileInput}
                multiple={true}
              />
              <div className="text-danger" id="fileUploadError">{errorField}</div>
              <div className="mt-2">
                <span className="mont-bold">Supported files:</span>
                <br></br>
                <span>JPG, PNG, PDF, DOC, DOCX, MP4 and MOV</span>
                <br></br>
                <span className="mont-bold">Maximum file size:</span>
                <br></br>
                <span>2 GB</span>
              </div>
            </div>
            <div className="uploaded-filelist mt-2">
              <h6>Files I'm Uploading</h6>
              {
                files && files.length === 0 ? (
                  <p id="filesUploadedList">No File Chosen</p>) : (
                  <ul>
                    {
                      files.map((file, i) => (
                        <li key={i} className="d-flex justify-content-between align-items-center">
                          <label>{file.name}</label>
                          <i id={`removeFile${i}`} role="button" aria-label="delete" className="ci-trash_full" onClick={() => handleRemove(i)}></i>
                        </li>
                      )
                      )
                    }
                  </ul>
                )
              }
            </div>
          </div>

          <div className="client-agreement-contaiuner">
            <h3 className="sub-header">
              Legal Consent
            </h3>
            <div className="d-flex flex-row align-items-center">
              <input
                type="checkbox"
                id="clientConsent"
                className="client-checkbox"
                name="clientConsent"
                value={clientConsent}
                onClick={onClientConsentChange}
                required={true}
              />
              <p>I agree that these documents belong to me and are released with my knowledge and at my direction.</p>
            </div>
          </div>
          <div className="form-group mt-4 submit-btn-container">
            <button
              type="submit"
              id="submitBtn"
              className="btn btn-primary custom-button border-change"
              disabled={files.length > 0 && clientConsent && !isSubmitting ? false : true}
            >Upload Files
              {isSubmitting ? (
                <span className="spinner-border spinner-border-sm" aria-label="Loading..."></span>
              ) : ""}
            </button >
            <div id="printError" className="printError text-danger"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SmrUploader;
